<template>
  <b-card-footer>
    <div class="flex-column justify-content-end">
      <!-- Soma em % -->
      <div class="text-right">
        <span class="font-weight-bold text-dark">Soma em %: </span>
        <span :class="percentClass">
          {{ formattedPercent }}%
        </span>
        <i :class="percentIcon" :title="percentTitle"></i>
      </div>

      <!-- Soma em R$ -->
      <div class="text-right">
        <span class="font-weight-bold text-dark">Soma em R$: </span>
        <span :class="currencyClass">
          {{ formattedCurrency }}
        </span>
        <i :class="currencyIcon" :title="currencyTitle"></i>
      </div>

      <!-- Diferença -->
      <div class="text-right">
        <span class="font-weight-bold text-dark">Diferença: </span>
        <span :class="differenceClass">
          {{ formattedDifferenceCurrency }} | {{ formattedDifferencePercent }}%
        </span>
        <i :class="differenceIcon" :title="differenceTitle"></i>
      </div>
    </div>
  </b-card-footer>
</template>

<script>
import { BCardFooter } from "bootstrap-vue";

export default {
  name: "StrategicSummary",
  components: {
    BCardFooter,
  },
  props: {
    strategicElement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Percentual
    percentClass() {
      return this.strategicElement.total_percent === 100
        ? "text-success"
        : "font-weight-bolder text-danger";
    },
    percentIcon() {
      return this.strategicElement.total_percent === 100
        ? "bi bi-check-circle-fill text-success"
        : "bi bi-exclamation-triangle-fill text-danger";
    },
    percentTitle() {
      return this.strategicElement.total_percent === 100
        ? "Percentual correto"
        : "Percentual incorreto";
    },
    formattedPercent() {
      // Garante que o valor seja numérico antes de aplicar toFixed
      const percent = Number(this.strategicElement.total_percent);
      return isNaN(percent) ? "0.00" : percent.toFixed(2);
    },

    // Moeda
    currencyClass() {
      return this.strategicElement.total_currency === this.strategicElement.total
        ? "text-success"
        : "font-weight-bolder text-danger";
    },
    formattedCurrency() {
      return this.$options.filters.toCurrency(this.strategicElement.total_currency, true);
    },
    currencyIcon() {
      return this.strategicElement.total_currency === this.strategicElement.total
        ? "bi bi-check-circle-fill text-success"
        : "bi bi-exclamation-triangle-fill text-danger";
    },
    currencyTitle() {
      return this.strategicElement.total_currency === this.strategicElement.total
        ? "Valores corretos"
        : "Diferença detectada";
    },

    // Diferença
    differenceClass() {
      return this.strategicElement.diferences.currency === 0 &&
        this.strategicElement.diferences.percent === 0
        ? "text-success"
        : "font-weight-bold text-danger";
    },
    formattedDifferenceCurrency() {
      return this.$options.filters.toCurrency(this.strategicElement.diferences.currency);
    },
    formattedDifferencePercent() {
      const diffPercent = Number(this.strategicElement.diferences.percent);
      return isNaN(diffPercent) ? "0.00" : diffPercent.toFixed(2);
    },
    differenceIcon() {
      return this.strategicElement.diferences.currency === 0 &&
        this.strategicElement.diferences.percent === 0
        ? "bi bi-check-circle-fill text-success"
        : "bi bi-exclamation-triangle-fill text-danger";
    },
    differenceTitle() {
      return this.strategicElement.diferences.currency === 0 &&
        this.strategicElement.diferences.percent === 0
        ? "Sem diferença"
        : "Diferença detectada";
    },
  },
};
</script>

<style scoped>
/* Adicione algum espaçamento entre as linhas se necessário */
.mb-2 {
  margin-bottom: 0.5rem;
}
</style>
