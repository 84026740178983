var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card-footer", [
    _c("div", { staticClass: "flex-column justify-content-end" }, [
      _c("div", { staticClass: "text-right" }, [
        _c("span", { staticClass: "font-weight-bold text-dark" }, [
          _vm._v("Soma em %: "),
        ]),
        _c("span", { class: _vm.percentClass }, [
          _vm._v(" " + _vm._s(_vm.formattedPercent) + "% "),
        ]),
        _c("i", { class: _vm.percentIcon, attrs: { title: _vm.percentTitle } }),
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c("span", { staticClass: "font-weight-bold text-dark" }, [
          _vm._v("Soma em R$: "),
        ]),
        _c("span", { class: _vm.currencyClass }, [
          _vm._v(" " + _vm._s(_vm.formattedCurrency) + " "),
        ]),
        _c("i", {
          class: _vm.currencyIcon,
          attrs: { title: _vm.currencyTitle },
        }),
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c("span", { staticClass: "font-weight-bold text-dark" }, [
          _vm._v("Diferença: "),
        ]),
        _c("span", { class: _vm.differenceClass }, [
          _vm._v(
            " " +
              _vm._s(_vm.formattedDifferenceCurrency) +
              " | " +
              _vm._s(_vm.formattedDifferencePercent) +
              "% "
          ),
        ]),
        _c("i", {
          class: _vm.differenceIcon,
          attrs: { title: _vm.differenceTitle },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }